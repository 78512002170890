import React from 'react';
import Main from "../components/main/Main";

function Home() {
    return (
        <>
        <Main/>
        </>
    );
}

export default Home;